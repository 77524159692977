<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-btn
      color="#aaa"
      class="my-back-btn-style"
      text
      x-large
      dark
      @click="back()"
    >
      <v-icon
        left
        dark
      >
        mdi-arrow-left
      </v-icon>
      Back
    </v-btn>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-map-marker-account
      </v-icon> Project Gate Check-Ins
    </h2>
    <v-card
      color="#FFF"
      class="project-list-card"
    >
      <br>
      <br>
      <v-card-title class="filter-card">
        <v-row>
          <v-col
            cols="12"
            lg="4"
            md="5"
            sm="12"
          >
            <v-text-field
              v-model="search"
              :hint="isSearchAdded ? searchHint : ''"
              persistent-hint
              label="Search"
              append-icon="mdi-magnify"
              outlined
              dense
              @keyup.enter="handleSearch"
              @keydown.esc="handleClearSearch"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <p
        v-if="!isSearchAdded"
        class="info-text"
      >
        <v-icon>mdi-account-hard-hat-outline</v-icon> Indicates Project Work <span class="division-style">|</span>
        <v-icon>mdi-account-wrench-outline</v-icon>Indicates Project Issue<span class="division-style">| </span>
        <v-icon>mdi-tablet-cellphone</v-icon> Indicates Kiosks<span class="division-style">| </span>
        <v-icon>mdi-swap-horizontal-bold</v-icon> Indicates Cost-Shift
      </p>
      <v-data-table
        :headers="headers"
        :items="projectGateCheckIns"
        :search="search"
        disable-sort
        :footer-props="{
          'items-per-page-options': [10, 20, 30]
        }"
        :loading="updateProgress"
        :server-items-length="totalRecords"
        class="elevation-1 table-projects"
        mobile-breakpoint="100"
        light
        :options.sync="options"
        :items-per-page="itemsPerPage"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.app_user.first_name }} {{ item.app_user.last_name }}</td>
            <td>{{ item.app_user.company.name }}</td>
            <td>
              <span v-if="item.app_user.worker_category !== null">
                {{ item.app_user.worker_category.name }}
              </span>
              <span v-else>
                N/A
              </span>
            </td>
            <td class="my-type-style">
              <v-chip
                outlined
                class="type"
                color="#37474F"
                dark
              >
                {{ item.type | staffToSiteManager }}<span v-if="item.type === 'worker'">
                  <v-icon
                    v-if="item.work_details.type === 'project-work'"
                    small
                  >mdi-account-hard-hat-outline</v-icon>
                  <v-icon
                    v-if="item.work_details.type === 'project-issue'"
                    small
                  >mdi-account-wrench-outline</v-icon>
                </span>
              </v-chip>
            </td>
            <td>
              <v-icon
                v-if="item.checkin_method_type === 'cost-shift'"
                color="#2E7D32"
                small
              >
                mdi-swap-horizontal-bold
              </v-icon>
              {{ item.checkin_time | convertToLocal }}
            </td>
            <td>
              <font v-if="item.checkin_kiosk !== null && item.checkin_method_type === 'cost-shift'">
                <v-icon
                  small
                >
                  mdi-tablet-cellphone
                </v-icon>
                N/A
              </font>
              <font v-if="item.checkin_kiosk !== null && item.checkin_method_type !== 'cost-shift'">
                <v-icon
                  small
                >
                  mdi-tablet-cellphone
                </v-icon>
                {{ item.checkin_kiosk.location_name }}
              </font>
              <font v-if="item.checkin_kiosk === null && item.checkin_method_type === 'cost-shift'">
                N/A
              </font>

              <font v-if="item.checkin_kiosk === null && item.checkin_method_type !== 'cost-shift'">
                {{ item.checkin_gate.name }}
              </font>
            </td>
            <td>
              <font
                v-if="item.checkout_time === null"
                class="My-checkout-time"
              >
                Onsite*
              </font><font v-else>
                {{ item.checkout_time | convertToLocal }}
              </font>
              <v-icon
                v-if="item.checkout_method_type === 'cost-shift'"
                color="#C62828"
                small
              >
                mdi-swap-horizontal-bold
              </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="py-3" />
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-container>
</template>
<script>
  import moment from 'moment';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';

  export default {
    name: 'ProjectGateCheckIns',
    components: {
      'centre-spinner': spinner,
    },
    filters: {
      convertToLocal (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
      },
      staffToSiteManager (val) {
        if (val === 'staff') {
          return 'Site Manager';
        }
        return val;
      },
    },
    data () {
      return {
        search: null,
        headers: [
          { text: 'Name', align: 'start', value: 'name' },
          { text: 'Company', value: 'company_name' },
          { text: 'Category', value: 'category' },
          { text: 'Check-In Type', value: 'type' },
          { text: 'Check-In At', value: 'checkin_time' },
          { text: 'Check-In Gate', value: 'checkin_gate.name' },
          { text: 'Check-Out At', value: 'country' },
        ],
        loading: false,
        updateProgress: false,
        options: {},
        itemsPerPage: 10,
        pageStart: 0,
      };
    },
    computed: {
      paginatedRecordsStructure () {
        return this.$store.getters['projectgates/getProjectGateCheckIns'];
      },
      projectGateCheckIns () {
        const records = this.paginatedRecordsStructure;
        return records.data;
      },
      totalRecords () {
        if (Object.keys(this.paginatedRecordsStructure).length > 0) {
          return this.paginatedRecordsStructure.total;
        }
        return 0;
      },
      isSearchAdded () {
        if (this.search === null || this.search === '') {
          return false;
        }
        return true;
      },
      searchHint () {
        return `Press Enter to filter by name: '${this.search}' or press ESC to clear.`;
      },
    },
    watch: {
      options: {
      async handler () {
        await this.paginatedCall();
      },
      deep: true,
    },
    },
    methods: {
      back () {
        this.$router.push({ name: 'Project Gates' });
      },
      async getRecordsFromServer () {
        this.updateProgress = true;
        const projectGateId = this.$route.params.projectGateId;
        await this.$store.dispatch('projectgates/fetchProjectGateCheckIns', {
          params: {
            params: {
              projectGateId: projectGateId,
              name: this.search === '' ? null : this.search,
              limit: this.itemsPerPage,
              startPage: this.pageStart,
              orderBy: 'desc',
            },
          },
        }).catch(() => {
          this.updateProgress = false;
        });
        this.updateProgress = false;
      },
      async handleSearch () {
        this.options.page = 1;
        this.pageStart = this.options.page * this.itemsPerPage - this.itemsPerPage;
        await this.getRecordsFromServer();
      },
      async handleClearSearch () {
        this.search = null;
        this.options.page = 1;
        await this.getRecordsFromServer();
      },
    async paginatedCall () {
    this.itemsPerPage = this.options.itemsPerPage;
    this.pageStart = this.options.page * this.itemsPerPage - this.itemsPerPage;
    this.getRecordsFromServer();
    },
    },
  };
</script>
<style scoped>
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC;
}
.v-data-table {
  background-color: #ECEFF1
}
.project-list-card {
  box-shadow: none !important;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.My-checkout-time {
  color:#2E7D32;
  font-weight: bold;
}
.my-type-style {
  text-transform: capitalize;
}
.my-back-btn-style {
  margin-bottom: 10px;
}
.info-text {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  font-style: italic;
  color: #37474F;
  margin-left: 15px;
  margin-top: -20px;
}
.division-style {
  margin-left: 5px;
  margin-right: 5px;
}
.table-projects {
  border-radius: 0px !important;
  background: #fff;
  box-shadow: none !important;
  border: 1px solid #ccc;
  border-top: none;
}
.project-list-card {
  box-shadow: none !important;
}
.table-projects tbody tr:nth-of-type(even) {
  background: #fff !important;
}
.table-projects tbody tr:nth-of-type(odd):hover {
  background: #fff !important;
}
.action-btn {
  background: #fff !important;
}
.filter-card {
  border: 1px solid #ccc !important;
  padding: 15px;
}
.table-top-border{
  border: 1px solid #ccc;
  border-bottom: none;
  background: #edf6f9;
}
.info-text{
  margin-bottom: 0px !important;
}
</style>
